

















































































enum ActiveBindMethod {
  Self = 'self',
  Code = 'code'
}

import BindChannelByCode, { BindChannelState } from "@/components/chat/BindChannelByCode.vue";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";

import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';

@Component({
  data() {
    return {
      ActiveBindMethod
    }
  },
  components: {
    BindChannelByCode,
    TextInput
  }
})
export default class BindChannel extends Mixins(InputSetups, TariffsTagsHelper) {
  @Prop() bindedChannelId!: number | string | null | undefined

  activeMethod: ActiveBindMethod = ActiveBindMethod.Self

  channelBindModal = false

  channelId = ''

  @Emit()
  onBind(channelId: string | number) {
    if (channelId) {
      if (typeof channelId === 'string') {
        this.channelBindModal = false

        return Number.parseInt(channelId)
      }

      this.channelBindModal = false
      return channelId
    }
  }

  get chat() {
    return this.$store.state.chatState.chat
  }

  get botName(): string {
    if (!this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveBrand || !this.$store.getters.getHaveLicense) {
      return atSignedLogin(CHATKEEPER_BOT_LOGIN)
    } else {
      return atSignedLogin(this.chat.brand.botname)
    }
  }

  handleOnChannelBind(data: BindChannelState) {
    if (data.binded && data.channel_id) {
      this.onBind(data.channel_id)
    }
  }
}

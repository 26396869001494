var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bind-channel"},[_c('div',{staticClass:"text-center"},[_c('a-button',{attrs:{"type":"primary","disabled":!!_vm.getTagsByFieldKey('branding')},on:{"click":function($event){_vm.channelBindModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('bind_channel'))+" ")])],1),_c('a-modal',{attrs:{"centered":"","title":_vm.$t('bind_channel'),"footer":null},model:{value:(_vm.channelBindModal),callback:function ($$v) {_vm.channelBindModal=$$v},expression:"channelBindModal"}},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center gap-2"},[_c('a-button',{class:{ 'active': _vm.activeMethod===_vm.ActiveBindMethod.Self },attrs:{"icon":"link"},on:{"click":function($event){_vm.activeMethod = _vm.ActiveBindMethod.Self}}},[_vm._v(" "+_vm._s(_vm.$t('bind_channel_self'))+" ")]),_c('a-button',{class:{ 'active': _vm.activeMethod === _vm.ActiveBindMethod.Code },attrs:{"icon":"idcard"},on:{"click":function($event){_vm.activeMethod = _vm.ActiveBindMethod.Code}}},[_vm._v(" "+_vm._s(_vm.$t('bind_channel_code'))+" ")])],1),(_vm.activeMethod === _vm.ActiveBindMethod.Self)?_c('div',{staticClass:"self-bind-method"},[_c('text-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'channelId',
            'validation': 'required'
          }
        }}}),_c('div',{staticClass:"text-center"},[_c('a-button',{staticClass:"btn-success btn-wrap",attrs:{"disabled":!_vm.channelId.trim()},on:{"click":function($event){return _vm.onBind(_vm.channelId)}}},[_vm._v(" "+_vm._s(_vm.$t('bind_channel'))+" ")])],1)],1):_vm._e(),(_vm.activeMethod === _vm.ActiveBindMethod.Code)?_c('div',{staticClass:"mt-2"},[_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('bind_channel_code_info', { bot: _vm.botName }))},slot:"message"})]),_c('bind-channel-by-code',{staticClass:"code-bind-method mt-5",attrs:{"bind-code-type":'CustomChannel'},on:{"onChannelBind":_vm.handleOnChannelBind}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }